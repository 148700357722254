<template>
  <div id="app">
    <div class="search">
      <div class="search-box">
        <el-input placeholder="请输入内容" v-model="search" class="input-with-select">
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
    </div>
    <div class="banner">
      <label>我是土狗我爱看</label>
      <img :src="require('@/assets/about_img.png')" alt="" />
    </div>
    <div class="content">
      <div class="text" v-for="(item, index) in filteredList" :key="index">
        {{ removePrefix(item.text) }} <span>资源来之不易跪求打赏</span>
        <a :href="item.link" target="_blank">{{ item.link }}</a>
      </div>
    </div>
    <!-- <HelloWorld /> -->
    <div ref="scrollTrigger" class="scroll-trigger"></div>
    <div class="dashang" @click="dialogVisible = true">
      <img :src="require('@/assets/ds.png')" alt="" />
    </div>
    <el-dialog title="谢谢大哥" :visible.sync="dialogVisible" class="dialog" :before-close="handleClose" :close-on-click-modal="false">
      <span>手机长按图片即可识别</span>
      <div class="dashang-box">
        <img :src="require('@/assets/erweima.jpg')" alt="" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { list as initialList } from '@/data/duanju'
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld
  },
  data() {
    return {
      search: '',
      list: [], // 当前已加载的数据
      dialogVisible: false,
      currentPage: 0,
      pageSize: 20 // 每次加载的数据条数
    }
  },
  computed: {
    // 通过搜索关键字在整个数据集中进行过滤
    filteredList() {
      const searchQuery = this.search.toLowerCase()
      if (searchQuery) {
        // 从初始数据中筛选出匹配的条目
        return initialList.filter((item) => item.text.toLowerCase().includes(searchQuery))
      }
      // 如果没有搜索关键字，则返回当前加载的数据
      return this.list
    }
  },
  mounted() {
    document.title = '我是土狗我爱看'
    this.loadMoreData()

    // 使用 IntersectionObserver 监视滚动触发加载更多数据
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.loadMoreData()
      }
    })
    observer.observe(this.$refs.scrollTrigger)
  },
  methods: {
    // 去掉文本前缀和特殊字符
    removePrefix(str) {
      return str.replace(/^\d+-/, '').replace(/\|/g, '')
    },
    // 关闭对话框
    handleClose() {
      this.dialogVisible = false
    },
    // 加载更多数据
    loadMoreData() {
      const start = this.currentPage * this.pageSize
      const end = start + this.pageSize
      const newData = initialList.slice(start, end)

      if (newData.length) {
        this.list = [...this.list, ...newData]
        this.currentPage++
      }
    }
  }
}
</script>

<style>
.search {
  position: sticky;
  z-index: 3;
  top: 0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.1);
}
.search-box {
  width: 96%;
  max-width: 800px;
  margin: auto;
}
.banner {
  width: 100%;
  max-width: 800px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.banner > img {
  display: block;
  max-width: 96%;
}
.banner > label {
  position: absolute;
  z-index: 2;
  font-weight: bold;
  left: 5%;
  top: 10%;
  font-size: 26px;
}
.content {
  width: 96%;
  max-width: 800px;
  margin: auto;
}
.content .text {
  margin-bottom: 10px;
  padding: 10px 15px;
  width: 100%;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  /* box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.1); */
}
.content .text > a {
  color: #fe4f70;
  display: block;
  margin-top: 10px;
}
.content .text > span {
  color: #f2aa18;
}
.scroll-trigger {
  height: 1px; /* 触发器的高度 */
}
.dashang {
  width: 60px;
  height: 60px;
  position: sticky;
  z-index: 3;
  bottom: 10%;
  left: 85%;
  background: #fff;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashang > img {
  width: 60%;
  height: 60%;
}
.dashang-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashang-box > img {
  width: 100%;
  display: block;
}
.dialog .el-dialog {
  width: 30%;
  margin-top: 7vh !important;
}
@media only screen and (max-width: 768px) {
  .content .text {
    font-size: 14px;
  }
  .dashang {
    width: 40px;
    height: 40px;
  }
  .content .text {
    padding: 5px 7px;
  }
  .content .text > a {
    margin-top: 5px;
  }
  .dialog .el-dialog {
    width: 86%;
    margin-top: 15vh !important;
  }
}
</style>
